.button {
  padding: 0.5rem 1rem;
  height: max-content;
  border-radius: theme('borderRadius.l');
  transition: border-color 0.2s, color 0.2s, background-color 0.2s;
}

.button:hover {
  box-shadow: theme('boxShadow.m');
}

.button[disabled]:hover {
  box-shadow: none;
}

.button svg {
  fill: currentColor;
}

/**
* Button Primary (default)
*/

.buttonPrimary {
  background-color: theme('colors.primary');
  color: theme('colors.white');
  border: 1px solid transparent;
  box-shadow: theme('boxShadow.base');
}

.buttonPrimary:hover,
.buttonPrimary:active {
  background-color: theme('colors.primary_darken');
  color: theme('colors.white');
  border: 1px solid theme('colors.primary');
}

.buttonPrimary[disabled] {
  background-color: theme('colors.primary');
  opacity: 50%;
  cursor: not-allowed;
  border: 1px solid transparent;
}

.buttonPrimary[disabled]:hover {
  color: theme('colors.white');
  border: 1px solid transparent;
}

/**
* Button Secondary / Button Tertiary
*/

.buttonSecondary,
.buttonTertiary {
  background-color: theme('colors.white');
  color: theme('colors.primary');
  border: 1px solid theme('colors.primary');
}

.buttonTertiary {
  background-color: theme('colors.primary_lighten');
  border: 1px solid transparent;
}

.buttonSecondary:hover,
.buttonSecondary:active {
  background-color: theme('colors.primary_lighten');
}

.buttonSecondary[disabled],
.buttonTertiary[disabled] {
  opacity: 50%;
  cursor: not-allowed;
}

/**
* Button Warning
*/

.buttonWarning {
  background-color: theme('colors.warning_lighten');
  color: theme('colors.warning');
  box-shadow: theme('boxShadow.base');
}

.buttonWarning:hover {
  background-color: theme('colors.warning');
  color: theme('colors.white');
}

.buttonWarning[disabled] {
  cursor: not-allowed;
}

.buttonWarning[disabled]:hover {
  background-color: theme('colors.warning_lighten');
  color: theme('colors.warning');
}

/**
* Button Primary Dark
*/

.buttonPrimaryDark {
  background-color: theme('colors.primary_darken');
  color: theme('colors.white');
  border: 1px solid transparent;
  box-shadow: theme('boxShadow.base');
}

.buttonPrimaryDark:hover,
.buttonPrimaryDark:active {
  background-color: theme('colors.primary_darken_strong');
  color: theme('colors.white');
  border: 1px solid theme('colors.primary_darken_strong');
}

