.chatRoom {
  width: 100vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: theme('colors.primary_lighten');
}

@media screen and (min-width: theme('screens.layout_s')) {
  .chatRoom {
    width: 20vw;
  }
}

@media screen and (min-width: theme('screens.layout_l')) {
  .chatRoom {
    width: 24vw;
  }
}
