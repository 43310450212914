.input:focus + label {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
}

.input[disabled] + label {
    background-color: theme('colors.primary');
    opacity: 50%;
    cursor: not-allowed;
    border: 1px solid transparent;
    color: theme('colors.white');
}
