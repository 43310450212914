.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 2rem;
  background-color: theme('colors.grey_700');
  border-radius: theme('borderRadius.full');
  border: 1px solid theme('colors.grey_700');
  cursor: pointer;
}

.toggle::after {
  content: '';
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: theme('borderRadius.full');
  background-color: white;
  top: 1px;
  left: 1px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbox:checked + .toggle {
  background-color: #397d54;
  border: 1px solid #397d54;
}

.checkbox:checked + .toggle::after {
  left: 1.25rem;
}

.checkbox:focus + .toggle {
  outline: auto;
}

.checkbox:disabled + .toggle {
  opacity: 0.6;
  filter: grayscale(40%);
  cursor: not-allowed;
}
